<script setup lang="ts">
const emit = defineEmits(["update:modelValue"]);
const props = defineProps<{
	modelValue: string[];
}>();

const isSelected = (id: string) => {
	return props.modelValue.includes(id);
};

const onClick = (id: string) => {
	const newModelValue = props.modelValue.includes(id)
		? props.modelValue.filter((i) => i !== id)
		: [...props.modelValue, id];
	emit("update:modelValue", newModelValue);
};
</script>

<template>
	<div class="flex overflow-x-auto snap-x lg:grid lg:grid-cols-3 gap-2">
		<slot :on-click="onClick" :is-selected="isSelected"></slot>
	</div>
</template>
